import React from "react";
import { UserbackProvider, useUserback } from "@userback/react";

function ControllerUserBack({countAfterSend} : {countAfterSend : number}) {
  const { open } = useUserback();

  React.useEffect(() => {
    open();
    document.querySelector(".userback-button")?.remove();
    document.querySelector(".userback-controls-close")?.remove();
  }, [open , countAfterSend]);

  return null;
}

function App() {
  const [countAfterSend,setCountAfterSend] = React.useState(0)
  return (
    <UserbackProvider
      options={{
        after_send: () => {
          setCountAfterSend((state)=>state+1)
        },
      }}
      token={process.env.REACT_APP_TOKEN_USER_BACK || ""}
    >
      <ControllerUserBack countAfterSend={countAfterSend} />
    </UserbackProvider>
  );
}

export default App;
